<template>
  <div>
    <!--    <div v-if="$screenIsSmall()">-->
    <!--      <div id="check-data">-->
    <!--        <v-form ref="form">-->
    <!--          <v-main>-->
    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <p>Monto</p>-->
    <!--                <h3 class="boldMountedText marginTopText">-->
    <!--                  ${{-->
    <!--                    Number($store.getters.getLoanAmount).toLocaleString("es-AR")-->
    <!--                  }}-->
    <!--                </h3>-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <p>Te deposito en tu cuenta de:</p>-->
    <!--                <h3 class="boldMountedText marginTopText">-->
    <!--                  {{ $store.getters.getBank }}-->
    <!--                </h3>-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <p>Me devolvés en:</p>-->
    <!--                <h3 class="boldMountedText marginTopText">-->
    <!--                  {{ $store.getters.getLoanInstallment }} cuotas de $-->
    <!--                  {{-->
    <!--                    Number($store.getters.getLoanAvgInstallment).toLocaleString(-->
    <!--                      "es-AR"-->
    <!--                    )-->
    <!--                  }}-->
    <!--                </h3>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-main>-->
    <!--        </v-form>-->

    <!--        <v-footer app>-->
    <!--          <v-row>-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnAmarillo"-->
    <!--                @click="next()"-->
    <!--              >-->
    <!--                Confirmar-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row>-->
    <!--            <v-col align="right" justify="right">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnWppAyuda btn-help&#45;&#45;margin"-->
    <!--                @click="$store.state.redirectToWhastapp()"-->
    <!--              >-->
    <!--                Ayuda-->
    <!--                <v-img-->
    <!--                  class="imageWppAyuda"-->
    <!--                  src="@/assets/img/icon_whatsapp_ayuda.png"-->
    <!--                  alt="wpp"-->
    <!--                />-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-footer>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <v-main>
      <v-row>
        <v-col align="center" justify="center" cols="12" sm="6" md="6">
          <v-row class="check-data__left-section">
            <v-col>
              <h2 class="darkPurple" style="margin-top: 30px">
                Confirmá tu préstamo
              </h2>
            </v-col>
          </v-row>
          <v-row class="check-data__main-img">
            <v-col>
              <v-img
                src="@/assets/img/yellow_pet_ok.svg"
                alt="Mascota saludando"
                contain
                max-height="300"
                min-height="300"
                max-width="60%"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col align-self="center" justify="center" cols="12" sm="6" md="6">
          <v-row>
            <v-col align="center" justify="center">
              <p>Monto</p>
              <h3 class="boldMountedText marginTopText">
                ${{
                  Number($store.getters.getLoanAmount).toLocaleString("es-AR")
                }}
              </h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="center" justify="center">
              <p>Te deposito en tu cuenta de:</p>
              <h3 class="boldMountedText marginTopText">
                {{ $store.getters.getBank }}
              </h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="center" justify="center">
              <p>Me devolvés en:</p>
              <h3 class="boldMountedText marginTopText">
                {{ $store.getters.getLoanInstallment }} cuotas de $
                {{
                  Number($store.getters.getLoanAvgInstallment).toLocaleString(
                    "es-AR"
                  )
                }}
              </h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-md-center text-center col-md-6 offset-md-6">
          <v-btn
            bottom
            depressed
            rounded
            x-large
            class="btnAmarillo"
            @click="next()"
          >
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
    <!--  </div>-->
    <terms-and-conditions v-show="false" id="tyctomail" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AutogestionDatosCotizacion",
  components: {
    TermsAndConditions: () => import("../legal/TermsAndConditions"),
  },
  data: () => ({
    valid: true,
    menu: false,
    headers: [],
    headersAnterior: [],
    items: [],
    itemsAnterior: [],
    show_form_mail: false,
  }),
  mounted() {
    console.log("Componente Datos Confirmacion creado");
    this.setStyleStepper("solicitud__stepper-img");
    this.$gtm.trackEvent({
      noninteraction: false,
      event: `${this.getPreffix()}_CONFIRMATION`,
      id_person: this.$store.getters.getUserForm,
    });
  },
  methods: {
    ...mapGetters(["getPreffix"]),
    ...mapActions(["setStyleStepper"]),
    back() {
      this.$emit("send-message", "6");
    },
    next() {
      this.$apiserver
        .finishLoanRequest(this.$store.getters.getLoan)
        .then(() => {
          this.$emit("send-message", "8");
          let form = {
            tyc: $("#tyctomail").prop("outerHTML"),
          };
          this.$apiserver.sendFormData(
            form,
            this.$apiserver.acceptLoanContract()
          );
        })
        .catch((error) => {
          this.$store.commit("ERROR_MODAL", true);
          console.log(error);
        });
    },
    sendMail() {
      this.show_form_mail = true;
    },
  },
};
</script>
